import {
  addBlog,
  AddChapters,
  AddCourse,
  addStudent,
  AddTopic,
  CoursesPurchasedByStudents,
  getblogs,
  GetChapters,
  GetConfirmOrderDetails,
  GetCourse,
  getStudents,
  GetTopic,
  GetTransactionsDetails,
  Logout,
  markFailed,
  markSuccess,
  registerStudent,
  sendOtpStudent,
  sendOtpTeacher,
  studentBuyCourse,
  studentChangePassword,
  studentForgotPassword,
  studentGetBlog,
  studentGetChapter,
  studentGetCourse,
  studentGetTopic,
  studentLogin,
  StudentLogout,
  teacherChangePassword,
  teacherForgotPassword,
  teacherLogin,
  updateBlog,
  UpdateChapters,
  UpdateCourse,
  UpdateTopic,
  verifyOtpStudent,
  verifyOtpTeacher,
} from "src/Utils/apiUrls";
import CallApi from "src/Utils/apiUtils";

//Teacher api's

export const TeacherLoginApi = (data) => ({
  payload: CallApi({
    url: teacherLogin,
    method: "POST",
    data: data,
  }),
});

export const VerifyOtpTeacherApi = (data) => ({
  payload: CallApi({
    url: verifyOtpTeacher,
    method: "POST",
    data: data,
  }),
});

export const SendOtpTeacherApi = (data) => ({
  payload: CallApi({
    url: sendOtpTeacher,
    method: "POST",
    data: data,
  }),
});

export const ForgotPasswordApi = (data) => ({
  payload: CallApi({
    url: teacherForgotPassword,
    method: "PUT",
    data: data,
  }),
});

export const AddBlogsApi = (data) => ({
  payload: CallApi({
    url: addBlog,
    method: "POST",
    data: data,
  }),
});

export const getAllBlogsApi = () => ({
  payload: CallApi({
    url: getblogs,
    method: "GET",
  }),
});

export const deleteBlogsApi = (id) => ({
  payload: CallApi({
    url: updateBlog + `${id}`,
    method: "DELETE",
  }),
});

export const updateBlogsApi = (id, data) => ({
  payload: CallApi({
    url: updateBlog + `${id}`,
    method: "PUT",
    data: data,
  }),
});

export const AddStudentApi = (data) => ({
  payload: CallApi({
    url: addStudent,
    method: "POST",
    data: data,
  }),
});

export const RegisterStudentApi = (data) => ({
  payload: CallApi({
    url: registerStudent,
    method: "POST",
    data: data,
  }),
});


export const getStudentApi = () => ({
  payload: CallApi({
    url: getStudents,
    method: "GET",
  }),
});

export const AddCourseApi = (data) => ({
  payload: CallApi({
    url: AddCourse,
    method: "POST",
    data: data,
  }),
});

export const GetCourseApi = () => ({
  payload: CallApi({
    url: GetCourse,
    method: "GET",
  }),
});

export const UpdateCourseApi = (id, data) => ({
  payload: CallApi({
    url: UpdateCourse + id,
    method: "PUT",
    data: data,
  }),
});

export const AddChapterApi = (id, data) => ({
  payload: CallApi({
    url: AddChapters + id + "/chapter",
    method: "POST",
    data: data,
  }),
});

export const GetChapterApi = (id) => ({
  payload: CallApi({
    url: GetChapters + id + "/chapter",
    method: "GET",
  }),
});

export const UpdateChapterApi = (courseId, id, data) => ({
  payload: CallApi({
    url: UpdateChapters + courseId + "/chapter/" + id,
    method: "PUT",
    data: data,
  }),
});

export const AddTopicApi = (id, data) => ({
  payload: CallApi({
    url: AddTopic + id + "/topic",
    method: "POST",
    data: data,
  }),
});

export const GetTopicApi = (id) => ({
  payload: CallApi({
    url: GetTopic + id + "/topic",
    method: "GET",
  }),
});

export const UpdateTopicApi = (chapterId, id, data) => ({
  payload: CallApi({
    url: UpdateTopic + chapterId + "/topic/" + id,
    method: "PUT",
    data: data,
  }),
});

export const TeacherChangePwd = (data) => ({
  payload: CallApi({
    url: teacherChangePassword,
    method: "PUT",
    data: data,
  }),
});

export const LogoutApi = () => ({
  payload: CallApi({
    url: Logout,
    method: "GET",
  }),
});

export const CoursesPurchasedByStudentsApi = () => ({
  payload: CallApi({
    url: CoursesPurchasedByStudents,
    method: "GET",
  }),
});

//Student api's

export const StudentLoginApi = (data) => ({
  payload: CallApi({
    url: studentLogin,
    method: "POST",
    data: data,
  }),
});

export const VerifyOtpStudentApi = (data) => ({
  payload: CallApi({
    url: verifyOtpStudent,
    method: "POST",
    data: data,
  }),
});

export const SendOtpStudentApi = (data) => ({
  payload: CallApi({
    url: sendOtpStudent,
    method: "POST",
    data: data,
  }),
});

export const ForgotPasswordApiStudent = (data) => ({
  payload: CallApi({
    url: studentForgotPassword,
    method: "PUT",
    data: data,
  }),
});

export const GetStudentCourseApi = () => ({
  payload: CallApi({
    url: studentGetCourse,
    method: "GET",
  }),
});

export const BuyStudentCourseApi = (id, data) => ({
  payload: CallApi({
    url: studentBuyCourse + id + "/buy",
    method: "POST",
    data: data,
  }),
});

export const LogoutStudentApi = () => ({
  payload: CallApi({
    url: StudentLogout,
    method: "GET",
  }),
});

export const StudentChangePwd = (data) => ({
  payload: CallApi({
    url: studentChangePassword,
    method: "PUT",
    data: data,
  }),
});

export const GetStudentChapterApi = (id) => ({
  payload: CallApi({
    url: studentGetChapter + `${id}/chapter`,
    method: "GET",
  }),
});

export const BuyStudentChapterApi = (courseId, chapterId, data) => ({
  payload: CallApi({
    url: studentBuyCourse + courseId + "/chapter/" + chapterId + "/buy",
    method: "POST",
    data: data,
  }),
});

export const GetStudentTopicApi = (id) => ({
  payload: CallApi({
    url: studentGetTopic + `${id}/topic`,
    method: "GET",
  }),
});

export const GetStudentBlogApi = (id) => ({
  payload: CallApi({
    url: studentGetBlog,
    method: "GET",
  }),
});

export const GetOrderConfirmationDetailsForCourse = (courseId) => ({
  payload: CallApi({
    url: `${GetConfirmOrderDetails}${courseId}/confirmOrderDetails`,
    method: "GET",
  }),
});

export const GetOrderConfirmationDetailsForChapter = (courseId, chapterId) => ({
  payload: CallApi({
    url: `${GetConfirmOrderDetails}${courseId}/confirmOrderDetails?chapterId=${chapterId}`,
    method: "GET",
  }),
});

export const MarkOrderFailed = (data, id) => ({
  payload: CallApi({
    url: markFailed + id + "/markFailed",
    method: "POST",
    data: data,
  }),
});

export const GetTransactionsDetailsApi = () => ({
  payload: CallApi({
    url: GetTransactionsDetails,
    method: "GET",
  }),
});

export const MarkOrderSucceed = (data, id) => ({
  payload: CallApi({
    url: markSuccess + id + "/verify",
    method: "POST",
    data: data,
  }),
});
