import React, { Component } from "react";
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Homepage from "./views/pages/Homepage";
import TermsConditions from "./views/pages/Terms&Conditions";
import PrivacyPolicy from "./views/pages/PrivacyPolicy";
import RefundCancellation from "./views/pages/RefundCancellation";
import Dashboard from "./views/Components/TeacherComponents/Blogs/Blogs";
import ShowBlogAtHome from "./views/Components/TeacherComponents/Blogs/ShowBlogAtHome";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const LoginTeacher = React.lazy(() => import("./views/pages/LoginTeacher"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/ForgotPassword/ForgotPwd")
);
const Register = React.lazy(() => import("./views/pages/register/Register"));

window.redirectToLogin = () => {
  window.location.href = "/home";
};

class App extends Component {
  render() {
    let token = localStorage.getItem("token");

    return (
      <BrowserRouter>
        <ToastContainer position="top-right" autoClose={2000} closeOnClick />
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/home"
              name="Home Page"
              render={(props) => <Homepage {...props} />}
            />
            <Route
              exact
              path="/login_student"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/student_register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/login_teacher"
              name="Login Page"
              render={(props) => <LoginTeacher {...props} />}
            />
            <Route
              exact
              path="/forgot_password"
              name="Login Page"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/terms-conditions"
              name="Home Page"
              render={(props) => <TermsConditions {...props} />}
            />
            <Route
              exact
              path="/privacy-policy"
              name="Home Page"
              render={(props) => <PrivacyPolicy {...props} />}
            />
            <Route
              exact
              path="/refund-policy"
              name="Home Page"
              render={(props) => <RefundCancellation {...props} />}
            />
             <Route
              exact
              path="/home_blogs"
              name="Blogs"
              render={(props) => <ShowBlogAtHome {...props} />}
            />
            {token ? (
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            ) : (
              <Redirect from="/" to="/home" />
            )}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
