const TermsConditions = () => {
  return (
    <div className="p-5">
      <h3 className="mb-4">Terms & Conditions</h3>
      <p className="mb-3" style={{fontSize: '18px'}}>
        This Site and all its Contents are intended solely for personal use. So,
        please do not share the content that is available to you with others. If
        you do so, then you will loose all your access from all the courses
        and chapters you purchased from us.
      </p>
      <p className="mb-3" style={{fontSize: '18px'}}>Turning Point Academy © 2021, All Rights Reserved</p>
    </div>
  );
};

export default TermsConditions;
