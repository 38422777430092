import axios from "axios";
import Loader from "src/views/Components/Loader/Loader";

const CallApi = async (options = {}) => {
  return new Promise (async(resolve, reject) => {
    if (!options.url) reject(new Error("URL parameter required"));
    if (!options.method) reject(new Error("API method required"));
   
    console.log("AXIOS WORKING !!");
    const headerOptions = {
      accesstoken: `${localStorage.getItem("token")}`,
    };

    if (options.multipart) {
      headerOptions["content-type"] = "multipart/form-data";
    }
    await axios(Object.assign(options, { headers: headerOptions }))
      .then((response) => {
        if (response.errors) {
          reject(response.errors);
        }
        resolve(Object.assign(response.data, { requestData: options.data }));
      })
      .catch((error) => {
        console.log(error.response, "ERR");
        if (error.response && error.response.status === 401) {
          console.log(error.response, "WORK");
          localStorage.removeItem("href");
          localStorage.removeItem("loginType");
          localStorage.removeItem("token");
          localStorage.removeItem("loggedInUserDetails");
          localStorage.removeItem("studentDetails");
          window.redirectToLogin();
        }

        if (error.response) {
          return reject(
            Object.assign(error.response, { requestData: options.data })
          );
        }
      });
  });
};

export default CallApi;
