import {
  CRow,
  CCol,
  CCard,
  CCardBody,
  CCardFooter,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CFormGroup,
  CTextarea,
  CLabel,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  AddBlogsApi,
  deleteBlogsApi,
  getAllBlogsApi,
  updateBlogsApi,
} from "src/Store/Actions";
import Loader from "../../Loader/Loader";

const Dashboard = () => {
  const [primary, setPrimary] = useState(false);
  const [editBlog, setEditBlog] = useState(false);
  const [blog, setBlog] = useState("");
  const [blogId, setBlogId] = useState("");
  const [blogDetails, setBlogsDetails] = useState([]);
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    getBlogDetails();
  }, []);

  const getBlogDetails = () => {
    getAllBlogsApi().payload.then((res) => {
      setShowData(true);
      setBlogsDetails(res.data);
    });
  };

  const addBlogFunc = () => {
    let obj = {
      blog,
    };
    AddBlogsApi(obj)
      .payload.then(() => {
        setPrimary(!primary);
        getBlogDetails();
        setBlog("");
        toast.success("Blog Added Successfully");
      })
      .catch((err) => {
        toast.error(err.data.error.message);
      });
  };

  const deleteBlogFunc = (id) => {
    deleteBlogsApi(id)
      .payload.then(() => {
        getBlogDetails();
        setEditBlog(false);
        setBlog("");
        toast.success("Blog Deleted Successfully");
      })
      .catch((err) => {
        toast.error(err.data.error.message);
      });
  };

  const setEditBlogDetails = (id, blogText) => {
    setEditBlog(true);
    setBlog(blogText);
    setBlogId(id);
    setPrimary(!primary);
  };

  const editBlogFunc = () => {
    let obj = {
      blog,
    };
    updateBlogsApi(blogId, obj)
      .payload.then(() => {
        getBlogDetails();
        setBlog("");
        setEditBlog(false);
        setBlogId("");
        setPrimary(!primary);
        toast.success("Blog Updated Successfully");
      })
      .catch((err) => {
        toast.error(err.data.error.message);
      });
  };

  const getDate = (value) => {
    let findDateValues = new Date(value);
    let date =
      ("0" + findDateValues.getDate()).slice(-2) +
      "/" +
      ("0" + (findDateValues.getMonth() + 1)).slice(-2) +
      "/" +
      findDateValues.getFullYear();
    let time = ("0" + findDateValues.getHours()).slice(-2) + ":" + ("0" + findDateValues.getMinutes()).slice(-2);
    return `Added on ${date} at ${time}`;
  };

  return (
    <>
      {showData ? <>
        <div className="mb-5">
          <CButton
            color="primary"
            className="py-2 w-100 col-12 col-lg-3 col-sm-6 col-md-4"
            onClick={() => setPrimary(!primary)}
          >
            Add New Blog
          </CButton>
        </div>
        <h4 className="mb-3">My Added Blogs</h4>
        <CRow>
          {(blogDetails || []).map((v, i) => {
            return (
              <CCol xs="12" sm="6" md="6" key={i}>
                <CCard>
                  <CCardBody>{v.blog}</CCardBody>
                  <CCardFooter className="d-flex align-items-center justify-content-between">
                    {getDate(v.createdAt)}
                    <div className="d-flex align-items-center">
                      <CButton
                        color="primary"
                        className="px-4 mr-3"
                        onClick={() => setEditBlogDetails(v.id, v.blog)}
                      >
                        Edit
                      </CButton>
                      <CButton
                        variant="outline"
                        color="primary"
                        className="px-4"
                        onClick={() => deleteBlogFunc(v.id)}
                      >
                        Delete
                      </CButton>
                    </div>
                  </CCardFooter>
                </CCard>
              </CCol>
            );
          })}
          {!blogDetails.length && <div className="details_not_available">No blogs added</div>}
        </CRow>
        <CModal
          show={primary}
          onClose={() => setPrimary(!primary)}
          color="primary"
          size="lg"
          keyboard={false}
          closeOnBackdrop={false}
        >
          <CModalHeader closeButton>
            <CModalTitle> {editBlog ? "Edit Blog" : "Add New Blog"}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CFormGroup row>
              <CCol xs="12" md="12">
                <CTextarea
                  name="textarea-input"
                  id="textarea-input"
                  rows="9"
                  placeholder="Type here..."
                  value={blog}
                  onChange={(e) => setBlog(e.target.value)}
                />
              </CCol>
            </CFormGroup>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="primary"
              onClick={() => {
                if (editBlog) {
                  editBlogFunc();
                } else {
                  addBlogFunc();
                }
              }}
            >
              {editBlog ? "Edit Blog" : "Add Blog"}
            </CButton>{" "}
            <CButton
              color="secondary"
              onClick={() => {
                setBlog("");
                setEditBlog(false);
                setBlogId("");
                setPrimary(!primary);
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
      </> : <Loader />}
    </>
  );
};

export default Dashboard;
