// const baseUrl = "http://172.105.61.108/api/turningPoint/";
const baseUrl = "https://learningatturningpoint.com/apis/";
export const materialUrl = "https://learningatturningpoint.com/apis/assets/";

export const teacherLogin = `${baseUrl}teacher/login`;
export const teacherForgotPassword = `${baseUrl}teacher/forgotPassword`;
export const teacherChangePassword = `${baseUrl}teacher/changePassword`;

export const sendOtpTeacher = `${baseUrl}teacher/sendOtpOnEmail`;
export const verifyOtpTeacher = `${baseUrl}teacher/verifyOtp`;

export const addBlog = `${baseUrl}teacher/blog`;
export const updateBlog = `${baseUrl}teacher/Blogs/`;
export const getblogs = `${baseUrl}teacher/Blogs`;

export const addStudent = `${baseUrl}teacher/addStudent`;
export const registerStudent = `${baseUrl}/student/register`;
export const getStudents = `${baseUrl}teacher/students`;

export const AddCourse = `${baseUrl}teacher/course`;
export const UpdateCourse = `${baseUrl}teacher/course/`;
export const GetCourse = `${baseUrl}teacher/course`;

export const AddChapters = `${baseUrl}teacher/course/`;
export const UpdateChapters = `${baseUrl}teacher/course/`;
export const GetChapters = `${baseUrl}teacher/course/`;

export const AddTopic = `${baseUrl}teacher/chapter/`;
export const UpdateTopic = `${baseUrl}teacher/chapter/`;
export const GetTopic = `${baseUrl}teacher/chapter/`;

export const GetTeacherDetails = `${baseUrl}/teacher/:id`;
export const Logout = `${baseUrl}/teacher/logout`;

export const studentLogin = `${baseUrl}student/login`;

export const sendOtpStudent = `${baseUrl}student/sendOtpOnEmail`;
export const verifyOtpStudent = `${baseUrl}student/verifyOtp`;
export const studentForgotPassword = `${baseUrl}student/forgotPassword`;
export const studentChangePassword = `${baseUrl}student/changePassword`;
export const studentGetCourse = `${baseUrl}student/course`;
export const studentBuyCourse = `${baseUrl}student/course/`;
export const StudentLogout = `${baseUrl}student/logout`;

export const studentGetChapter = `${baseUrl}student/course/`;
export const studentGetTopic = `${baseUrl}student/chapter/`;

export const studentGetBlog = `${baseUrl}student/Blogs`;

export const GetConfirmOrderDetails = `${baseUrl}student/course/`;

export const GetTransactionsDetails = `${baseUrl}student/transactions`;

export const CoursesPurchasedByStudents = `${baseUrl}/teacher/coursesPurchasedByStudents`;

export const markFailed = `${baseUrl}student/order/`;
export const markSuccess = `${baseUrl}student/order/`;
