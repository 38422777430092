import { CButton, CContainer, CNavbar, CNavbarBrand } from "@coreui/react";

const Homepage = () => {
  return (
    <div>
      <CNavbar
        colorScheme="dark"
        className="bg-dark"
        placement="sticky-top"
        sticky={true}
      >
        <CContainer fluid className="d-flex justify-content-between">
          <CNavbarBrand href="#">Turning Point Academy</CNavbarBrand>

          <div className="nav_bar_text">
            <CNavbarBrand href="/home_blogs">Blogs</CNavbarBrand>
            <CNavbarBrand href="/terms-conditions">
              Terms & Conditions
            </CNavbarBrand>
            <CNavbarBrand href="/privacy-policy">Privacy Policy</CNavbarBrand>
            <CNavbarBrand href="/refund-policy">
              Refunds/Cancellations
            </CNavbarBrand>
          </div>
        </CContainer>
      </CNavbar>

      <div className="homepage" style={{ height: "calc(100vh - 55px)" }}>
        <div id="overlay">
          <div className="pt-5">
            <h1 className="text-center pt-5 px-3">
              Welcome to the Turning Point Academy
            </h1>
          </div>
          <div className="pt-3">
            <h3 className="text-center px-3">
              We provide you best courses which helps towards your career.
            </h3>
          </div>

          <div className="row m-0 mt-5">
            <div className="col-12 d-flex justify-content-center mt-5">
              <CButton
                component="a"
                to="/login_teacher"
                color="primary"
                size="lg"
              >
                Continue as teacher
              </CButton>
            </div>
          </div>
          <div className="row m-0 mt-4">
            <div className="col-12 d-flex justify-content-center">
              <CButton
                component="a"
                to="/login_student"
                color="success"
                size="lg"
              >
                Continue as student
              </CButton>
            </div>
          </div>
        </div>
      </div>

      <div className="about mt-3">
        <div className="">
          <h1 className="text-center pt-5 px-3">About Us</h1>
        </div>

        <p className="text-center pt-4 px-5" style={{ fontSize: "18px" }}>
          We are good and simple coaching institute based on Khandawa M.P. for
          the school students. Here we provide courses of almost every subject
          at an affordable cost. Before september 2021, we used to teach our
          students offline, but from now, we will make our courses available
          online, so that every student who access this site can get the course
          and start learning from us.
        </p>
      </div>

      <div className="contactUs mt-5 pb-5" style={{ background: "#fff" }}>
        <div className="mt-5">
          <h1 className="text-center pt-5 px-3">Contact Us</h1>
        </div>

        <div className="row m-0 mt-5">
          <div className="col-lg-4 col-12 d-flex justify-content-center align-items-center flex-column">
            <h4>Address</h4>
            <p>Near Bombay bazar Khandwa,(M.P.) India</p>
          </div>
          <div className="col-lg-4 col-12 d-flex justify-content-center align-items-center flex-column">
            <h4>Contact Number</h4>
            <p class="m-0 p-0">+91 77720 42335</p>
          </div>
          <div className="col-lg-4 col-12 d-flex justify-content-center align-items-center flex-column">
            <h4>Email</h4>
            <p>learningatturningpoint@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
