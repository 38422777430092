const RefundCancellation = () => {
  return (
    <div className="p-5">
      <h3 className="mb-4">Refund & Cancellation</h3>
      <p className="mb-3" style={{fontSize: '18px'}}>
        There is no option available in this website to cancel your purchase
        order i.e. any course or chapter, and you cannot apply for the refund on
        your any successful transaction.
      </p>
      <p className="mb-3" style={{fontSize: '18px'}}>
        If you have any issue regarding payment failure or anything then you can
        contact us directly.
      </p>
    </div>
  );
};

export default RefundCancellation;
