const PrivacyPolicy = () => {
  return (
    <div className="p-5">
      <h3 className="mb-4">Privacy Policy</h3>
      <h4 className="mb-2 mt-5">Most importantly:</h4>
      <p className="mb-3" style={{fontSize: '18px'}}>
         We will not sell, trade, or give the details of our
        users that we collect in this site to any third party. The data that we
        collect is not mined or used for marketing purposes in any way. The data
        that we collect is used solely in this site to make our users experience
        better. Be assured that safeguarding our users details is our priority.
      </p>
      <p className="mb-3" style={{fontSize: '18px'}}>
        This policy may change from time to time so please re-read this policy
        periodically.
      </p>
      <h4 className="mb-2 mt-5">What We Gather From Our Users:-</h4>
      <p className="mb-3" style={{fontSize: '18px'}}>
        In this site, we
        collect users information during their registration. Information that we
        request from our users are their email, mobile, standard, and subject.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
