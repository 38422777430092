import {
    CRow,
    CCol,
    CCard,
    CCardBody,
    CCardFooter,
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CFormGroup,
    CTextarea,
    CLabel,
  } from "@coreui/react";
  import React, { useEffect, useState } from "react";
  import { toast, ToastContainer } from "react-toastify";
  import {
    AddBlogsApi,
    deleteBlogsApi,
    getAllBlogsApi,
    updateBlogsApi,
  } from "src/Store/Actions";
  import Loader from "../../Loader/Loader";
  
  const ShowBlogAtHome = () => {
    const [primary, setPrimary] = useState(false);
    const [editBlog, setEditBlog] = useState(false);
    const [blog, setBlog] = useState("");
    const [blogId, setBlogId] = useState("");
    const [blogDetails, setBlogsDetails] = useState([]);
    const [showData, setShowData] = useState(false);
  
    useEffect(() => {
      getBlogDetails();
    }, []);
  
    const getBlogDetails = () => {
      getAllBlogsApi().payload.then((res) => {
        setShowData(true);
        setBlogsDetails(res.data);
      });
    };
  
    const addBlogFunc = () => {
      let obj = {
        blog,
      };
      AddBlogsApi(obj)
        .payload.then(() => {
          setPrimary(!primary);
          getBlogDetails();
          setBlog("");
          toast.success("Blog Added Successfully");
        })
        .catch((err) => {
          toast.error(err.data.error.message);
        });
    };
  
    const deleteBlogFunc = (id) => {
      deleteBlogsApi(id)
        .payload.then(() => {
          getBlogDetails();
          setEditBlog(false);
          setBlog("");
          toast.success("Blog Deleted Successfully");
        })
        .catch((err) => {
          toast.error(err.data.error.message);
        });
    };
  
    const setEditBlogDetails = (id, blogText) => {
      setEditBlog(true);
      setBlog(blogText);
      setBlogId(id);
      setPrimary(!primary);
    };
  
    const editBlogFunc = () => {
      let obj = {
        blog,
      };
      updateBlogsApi(blogId, obj)
        .payload.then(() => {
          getBlogDetails();
          setBlog("");
          setEditBlog(false);
          setBlogId("");
          setPrimary(!primary);
          toast.success("Blog Updated Successfully");
        })
        .catch((err) => {
          toast.error(err.data.error.message);
        });
    };
  
    const getDate = (value) => {
      let findDateValues = new Date(value);
      let date =
        ("0" + findDateValues.getDate()).slice(-2) +
        "/" +
        ("0" + (findDateValues.getMonth() + 1)).slice(-2) +
        "/" +
        findDateValues.getFullYear();
      let time = ("0" + findDateValues.getHours()).slice(-2) + ":" + ("0" + findDateValues.getMinutes()).slice(-2);
      return `Added on ${date} at ${time}`;
    };
  
    return (
      <>
       <div className="m-5">
       {showData ? <>
          <div className="mb-5">
           
          </div>
          <h4 className="mb-3">Blog Details</h4>
          <CRow>
            {(blogDetails || []).map((v, i) => {
              return (
                <CCol xs="12" sm="12" md="12" lg="12" key={i}>
                  <CCard>
                    <CCardBody>{v.blog}</CCardBody>
                    <CCardFooter className="d-flex align-items-center justify-content-between">
                      {getDate(v.createdAt)}
                      {/* <div className="d-flex align-items-center">
                        <CButton
                          color="primary"
                          className="px-4 mr-3"
                          onClick={() => setEditBlogDetails(v.id, v.blog)}
                        >
                          Edit
                        </CButton>
                        <CButton
                          variant="outline"
                          color="primary"
                          className="px-4"
                          onClick={() => deleteBlogFunc(v.id)}
                        >
                          Delete
                        </CButton>
                      </div> */}
                    </CCardFooter>
                  </CCard>
                </CCol>
              );
            })}
            {!blogDetails.length && <div className="details_not_available">No blogs added</div>}
          </CRow>
        </> : <Loader />}
       </div>
      </>
    );
  };
  
  export default ShowBlogAtHome;
  